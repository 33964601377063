<template>
    <div class="accordion mt-7">
        <OneItemAccordion v-for="(oneItem, index) in data" :key="`faq_${index}`" :body="oneItem.answer" :title="oneItem.question" :active="activeTab.includes(index)" @click="active(index)" />
    </div>
</template>
<script setup lang="ts">
import OneItemAccordion from '~/components/Accordion/OneItemAccordion.vue';

defineProps<{ data: { answer: string; question: string }[] }>();

const activeTab = ref([]); //default not active
const active = index => {
    activeTab.value.includes(index) ? (activeTab.value = activeTab.value.filter(item => item !== index)) : activeTab.value.push(index);
};
</script>

<style lang="scss" scoped>
.accordion {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}
</style>
